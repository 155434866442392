import { css } from "styled-components";

import colors from "constants/colors";
import media, { sizes } from "constants/media";

export const cssVariables = css`
    /*
     * Transitions
     */
    --animation-duration-fast: 100ms;
    --animation-duration-default: 200ms;
    --animation-duration-long: 500ms;

    --animation-easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
    --animation-easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
    --animation-easing-accelerate: cubic-bezier(0.4, 0, 1, 1);

    /*
     * Borders
     */
    --border-radius-xs: 3px;
    --border-radius-small: 4px;
    --border-radius-default: 5px;
    --border-radius-medium: 7px;
    --border-radius-card: 21px;

    /*
     * Colors
     */
    --color-white: ${colors.white};
    --color-black: ${colors.black};

    --color-blue-60: ${colors.blue60};
    --color-blue-70: ${colors.blue70};
    --color-violet-20: ${colors.violet20};
    --color-violet-30: ${colors.violet30};
    --color-violet-40: ${colors.violet40};
    --color-violet-50: ${colors.violet50};
    --color-violet-75: ${colors.violet75};
    --color-violet-80: ${colors.violet80};
    --color-violet-85: ${colors.violet85};
    --color-violet-90: ${colors.violet90};
    --color-violet-95: ${colors.violet95};

    --color-green-50: ${colors.green50};
    --color-green-60: ${colors.green60};
    --color-green: var(--color-green-50);

    --color-red-40: ${colors.red40};
    --color-red-50: ${colors.red50};
    --color-red-60: ${colors.red60};
    --color-red: var(--color-red-50);

    --color-gray-40: ${colors.gray40};
    --color-gray-60: ${colors.gray60};
    --color-gray-65: ${colors.gray65};
    --color-gray-70: ${colors.gray70};
    --color-gray-75: ${colors.gray75};
    --color-gray-80: ${colors.gray80};
    --color-gray-80: ${colors.gray80};
    --color-gray-85: ${colors.gray50};
    --color-gray-90: ${colors.gray90};

    --color-primary: var(--color-violet-40);
    --color-secondary: var(--color-red-50);
    --color-tertiary: var(--color-black);

    /*
     * Shadows
     */
    --box-shadow-mobile: 0 5px 24px 0 rgba(49, 49, 62, 0.1);
    --box-shadow-desktop: 0 18px 28px 0 rgba(0, 0, 0, 0.1);
    --box-shadow-standard: 0 18px 28px 0 rgba(0, 0, 0, 0.1);
    --box-shadow-input: 0 3px 7px 0 rgba(49, 49, 62, 0.19);

    /*
     * Typography
     */
    --font-family-primary: "DM Sans", Tahoma, sans-serif;

    --font-weight-normal: 400;
    --font-weight-bold: 700;

    --font-size-h1: 30px;
    --font-size-h2: 28px;
    --font-size-h3: 26px;
    --font-size-h4: 22px;
    --font-size-h5: 18px;
    --font-size-h6: 16px;
    --font-size-body: 16px;

    --line-height-h1: 40px;
    --line-height-h2: 35px;
    --line-height-h3: 35px;
    --line-height-h4: 30px;
    --line-height-h5: 25px;
    --line-height-h6: 20px;
    --line-height-body: 30px;

    /*
     * Giftcard
     */
    --aspect-ratio-card: 37 / 24;

    /*
     * Container
     */
    --total-columns: 12;
    --gutter: 20px;
    --container-width: 100%;
    /* prettier-ignore */
    --1-column-width: calc((var(--container-width) - (var(--total-columns) * var(--gutter))) / var(--total-columns));

    ${media.mdUp} {
        /*
         * Borders
         */
        --border-radius-card: 20px;

        /*
         * Typography
         */
        --font-size-h1: 55px;
        --font-size-h2: 40px;
        --font-size-h3: 30px;
        --font-size-h4: 25px;
        --font-size-h5: 19px;
        --font-size-h6: 16px;

        --line-height-h1: 75px;
        --line-height-h2: 55px;
        --line-height-h3: 40px;
        --line-height-h4: 35px;
        --line-height-h5: 30px;
        --line-height-h6: 25px;

        /*
         * Container
         */
        --container-width: ${sizes.md}px;
    }

    ${media.lgUp} {
        /*
         * Borders
         */
        --border-radius-card: 10px;

        /*
         * Container
         */
        --container-width: ${sizes.lg}px;
    }

    ${media.xlUp} {
        /*
         * Container
         */
        --container-width: ${sizes.xl}px;
    }
`;
