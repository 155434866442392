const colors = {
    white: "#ffffff",
    black: "#000000",
    blue60: "#d6e1e6",
    blue70: "#f0f6f8",
    violet20: "#09048F",
    violet30: "#3204f5",
    violet40: "#401dd6",
    violet50: "#6236ff",
    violet75: "#c2d1d9",
    violet80: "#d6e1e6",
    violet85: "#e5e6e7",
    violet90: "#f9fafc",
    violet95: "#fefefe",
    green50: "#40e76f",
    green60: "#ecfdf0",
    red40: "#e02020",
    red50: "#ff3a4a",
    red60: "#ffebed",
    gray40: "#7f7f7f",
    gray50: "#808080",
    gray60: "#9595a6",
    gray65: "#c7c7c7",
    gray70: "#cacaca",
    gray75: "#e5e5e5",
    gray80: "#ebebeb", // rgba(0,0,0,0.1)
    gray85: "#eaeaea", // rgba(151,151,151,0.2)
    gray90: "#f2f2f2" // rgba(0, 0, 0, 0.05)
};

colors.red = colors.red50;
colors.green = colors.green50;

colors.primary = colors.violet40;
colors.secondary = colors.red50;
colors.tertiary = colors.black;

export default colors;
