import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { ModalBase } from "components/ui/modal/Modal.styled";

import { cssVariables } from "./variables";

export default createGlobalStyle`
    :root {
        ${cssVariables}
    }

    ${normalize}

    *,
    *::before,
    *::after {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    html, body, #__next {
        height: 100%;
        min-height: 100%;
    }

    html, body {
        margin: 0;
        padding: 0;
    }

    body, button, input, select, textarea {
        color: var(--color-black);
        font-family: var(--font-family-primary);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    p {
        font-size: var(--font-size-body);
        line-height: var(--line-height-body);
    }

    a {
        color: var(--color-primary);
        font-size: inherit;
        line-height: inherit;
        text-decoration: none;

        cursor: pointer;
    }

    /* Specific theme variables */
    ${ModalBase}

    .grecaptcha-badge {
        /* stylelint-disable-next-line declaration-no-important */
        z-index: 1000 !important;

        @media screen and (max-width: 992px) {
            /* stylelint-disable-next-line declaration-no-important */
            visibility: hidden !important;
        }
    }
`;
