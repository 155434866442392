import styled, { css } from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    position: relative;

    max-width: 580px;
    min-height: 100vh;
    margin: 0 0 0 auto;

    background-color: var(--color-white);

    ${media.mdUp} {
        height: 100%;
        min-height: unset;
        margin: 0;

        border-radius: var(--border-radius-default);
        box-shadow: 0 20px 200px 0 rgba(33, 33, 35, 0.52);
    }
`;

export const Preheader = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 9px 10px;

    background-color: var(--color-white);
    border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
`;

export const Close = styled.button`
    color: var(--color-secondary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    background: none;
    border: none;
    cursor: pointer;

    svg {
        margin-left: 5px;
    }

    @media (any-hover: hover) {
        :hover {
            color: var(--color-primary);
        }
    }
`;

export const ModalBase = css`
    .ReactModal__Overlay,
    .ReactModal__Content {
        transition: all 0.3s ease-in-out;
    }

    .ReactModal__Overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 40;

        background: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0);

        &--after-open {
            background: rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(2px);
        }

        &--before-close {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0);
        }
    }

    .ReactModal__Content {
        position: absolute;
        right: -100vw;
        z-index: 14;

        width: calc(100vw - 20px);
        max-height: 100%;
        overflow: hidden scroll;

        background: transparent;
        outline: none;
        opacity: 0;

        ${media.mdUp} {
            top: 50%;
            left: 50%;

            display: flex;
            justify-content: center;

            width: 100%;
            padding: 40px 20px;

            transform: translate(-50%, -50%);
        }

        &--after-open {
            right: 0;

            opacity: 1;

            ${media.mdUp} {
                width: 100%;
            }
        }

        &--before-close {
            right: -100vw;

            opacity: 0;
        }
    }
`;
